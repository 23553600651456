:root {
  // Colors
  --font-color: #2c2621;
  --brown: #2a211c;
  --black: #000000;
  --gold: #f8a000;
  --grey: #868686;
  --light-brown: #5c534e;
  --light-grey: #cccccc;
  --light-grey2: #ececec;
  --light-grey3: #f4f4f4;
  --grey-smoke: #dedede;
  --white: #ffffff;
  --whitesmoke: #f5f4f4;

  // Rgb Colors
  --gold-rgb: 248, 160, 0;

  // Font Size
  --font-size1: 1em;
  --font-size2: 2em;
  --font-size3: 3em;
  --font-size4: 0.9em;

  // Breakpoints
  --riza-width-xxl: 1366px;
  --riza-width-xl: 1140px;
  --riza-width-lg: 960px;
  --riza-width-md: 720px;
  --riza-width-sm: 540px;
  --riza-width-xs: 100%;

  // Product Card
  --productCardQuantity: 5;
  --productCardWidth: 320px;

  // Team Expertise
  --qtdSlide: '';

  // Set the Font family of the entire app
  --ion-font-family: 'MontserratLight', sans-serif;
  --ion-text-color: var(--font-color);

  // Set the Backdrop color of the entire app
  --ion-backdrop-color: var(--ion-color-dark);

  // Set the Padding of the entire app
  --ion-padding: 20px;

  // Set the Background of the entire app
  --ion-background-color: var(--white);
}
// Riza Fonts
@font-face {
  font-family: 'FiraLight';
  src: url('/assets/fonts/fira-sans-latin-light.woff2');
  font-display: swap;
}
@font-face {
  font-family: 'FiraRegular';
  src: url('/assets/fonts/fira-sans-latin-regular.woff2');
  font-display: swap;
}
@font-face {
  font-family: 'FiraMedium';
  src: url('/assets/fonts/fira-sans-latin-medium.woff2');
  font-display: swap;
}
@font-face {
  font-family: 'FiraBold';
  src: url('/assets/fonts/fira-sans-latin-bold.woff2');
  font-display: swap;
}
@font-face {
  font-family: 'MontserratLight';
  src: url('/assets/fonts/montserrat-latin-light.woff2');
  font-display: swap;
}
@font-face {
  font-family: 'MontserratBold';
  src: url('/assets/fonts/montserrat-latin-bold.woff2');
  font-display: swap;
}

// Fonts - For specific use of fonts
.fira-light {
  font-family: 'FiraLight';
}
.fira-regular {
  font-family: 'FiraRegular';
}
.fira-medium {
  font-family: 'FiraMedium';
}
.fira-bold {
  font-family: 'FiraBold';
}
.montserrat-light {
  font-family: 'MontserratLight';
}
.montserrat-bold {
  font-family: 'MontserratBold';
}

* {
  font-family: 'MontserratLight';
  line-height: 2em;
  font-weight: bold;
  strong {
    font-family: 'MontserratBold';
  }
}

.simple-container {
  width: var(--riza-width-xl);
  margin: 0 auto;
  padding: 20px 0;
}

.title-mark {
  font-size: calc(var(--font-size2) * 1.3);
  line-height: 1em;
  margin-bottom: 1em;
  text-transform: uppercase;
  position: relative;
  ion-icon {
    color: var(--ion-color-gold);
  }
  .base-line {
    background-color: var(--font-color);
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 40px;
    height: 5px;
  }
}

.loading {
  display: grid;
  place-content: center;
  height: 100%;
  // ion-spinner {
  //   color: var(--gold);
  // }
}

ion-toolbar .header-ios {
  --border-width: 0;
}

.header-ios ion-toolbar:last-of-type {
  --border-width: 0;
}

.header-md::after {
  background-image: none;
}

.popover-content.sc-ion-popover-ios {
  overflow: hidden;
}

.searchbar-search-icon.sc-ion-searchbar-ios {
  width: 18px !important;
}

// .modal-login-custom {
//   .modal-wrapper.sc-ion-modal-md {
//     max-height: 300px;
//     max-width: 500px;
//   }
// }

// .modal-register-custom .modal-wrapper {
//   max-width: 450px;
//   max-height: 600px;
//   overflow-y: auto;
// }

.modal-login-custom {
  --max-height: 350px;
  --max-width: 500px;
  .ion-page {
    background-image: url('../src/assets/images/logo/hexagonos.svg');
    background-position: right 0% top 40%;
    background-repeat: no-repeat;
  }
}

.modal-delete-custom {
  --max-height: 350px;
  --max-width: 500px;
  .ion-page {
    background-image: url('../src/assets/images/logo/hexagonos.svg');
    background-position: right 0% top 0%;
    background-repeat: no-repeat;
  }
}

.modal-unsubscribe-custom {
  --max-height: 100vh;
  --max-width: 550px;
  .ion-page {
    background-image: url('../src/assets/images/logo/hexagonos.svg');
    background-position: right 0% top 0%;
    background-repeat: no-repeat;
  }
}

.modal-register-custom {
  --max-width: 80vw;
  --max-height: 80vh;
  overflow-y: auto;
  .ion-page {
    background-image: url('../src/assets/images/logo/hexagonos.svg');
    background-position: right -1% top -2%;
    background-repeat: no-repeat;
  }
}

.modal-register-custom .modal-wrapper .ion-page {
  justify-content: flex-start;
}

.custom-alert .alert-wrapper {
  background-image: url('../src/assets/images/logo/hexagonos.svg');
  background-repeat: no-repeat;
  background-position: right 5% top -2%;
  max-width: 352px;
  font-family: 'MontserratBold';
  color: var(--font-color);
}

ion-toast {
  --background: var(--font-color);
  --border-color: var(--gold);
  --border-radius: 0px;
  --border-style: solid;
  --border-width: 0 0 7px 0;
  --box-shadow: var(--ion-color-gold);
  --button-color: var(--gold);
  --color: var(--white);
  font-family: 'MontserratLight';
}

/* CSS do Botão Close dos Modais */
// .close {
//   display: flex;
//   justify-content: end;
//   ion-icon {
//     font-size: 2em;
//     padding: 3px;
//     cursor: pointer;
//   }
// }
// ion-infinite-scroll-content {
//   &::part(scroll) {
//     padding-bottom: 0px;
//   }
// }

/* css button - global */
ion-button {
  font-family: 'MontserratBold';
  --border-radius: 0;
  --color: var(--font-color);
  --background: var(--gold);
  --box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-transform: uppercase;
}

ion-item {
  --background-activated: rgba(var(--gold-rgb), 0.5);
  --background-focused: rgba(var(--gold-rgb), 0.5);
}

ion-item,
ion-segment,
ion-select {
  --ripple-color: rgba(var(--gold-rgb), 0.5);
}

ion-toast::part(button) {
  font-family: 'MontserratBold';
  background-color: var(--gold);
  color: var(--font-color);
  padding: 5px;
}

ion-ripple-effect {
  --color: rgba(var(--gold-rgb), 0.5);
}

ion-spinner {
  --color: var(--gold);
}

@media only screen and (max-width: 1200px) {
  .simple-container {
    width: var(--riza-width-lg);
  }
}
@media only screen and (max-width: 992px) {
  .simple-container {
    width: var(--riza-width-xs);
    margin: 0;
    padding: 20px;
  }
}

@media only screen and (max-width: 576px) {
  .modal-register-custom .modal-wrapper {
    max-width: 100vw;
    max-height: 100vh;
  }

  .modal-register-custom {
    --max-width: 100%;
    --max-height: 100%;
  }

  .simple-container {
    padding: 10px;
  }
}
